import "../style/home.css"
import Carousel from 'react-bootstrap/Carousel';
import image1 from "../images/carousel1.webp"
import image2 from "../images/carousel2.webp"
import image3 from "../images/carousel3.webp"
import { useState } from "react";
import { Link } from "react-router-dom";

function ExampleCarouselImage({ img, text }) {
  const backgroundImageStyle = {
    backgroundImage: `url(${img})`,
    width: "100vw",
    height: "93vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "85px",
    fontFamily: "Poppins",
    fontWeight: "600",
    color: "white",
    backgroundSize: "cover",
  };
  return (
    <div className="carousel-image" style={backgroundImageStyle}>{text}</div>
  )
}

function CarouselFadeExample() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <ExampleCarouselImage text="Sartorial Excellence" img={image1} />
        <Carousel.Caption>
          <h3>Unrivaled Selections of Finest Fabrications for Superlative Attire.</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ExampleCarouselImage text="Elegance Redefined" img={image2} />
        <Carousel.Caption>
          <h3>A Distinctive Curation of Opulent Sarees.</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ExampleCarouselImage text="Exquisite Selections" img={image3} />
        <Carousel.Caption>
          <h3>Explore the unparalleled Curation of Finest Attire for Ladies.</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

function Card({ img, name }) {
  const homeCardImage = {
    backgroundImage: `url(${img})`,
    width: "300px",
    height: "320px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "20px"
  }
  const homeCardName = {
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: "300",
    color: "black"
  }
  const homeCard = {
    width: "300px",
    height: "350px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "15px",
    marginBottom: "15px"
  }
  return (
    <div className="home-card" style={homeCard}>
      <div className="home-card-image" style={homeCardImage}></div>
    </div>
  )
}

function Card2({ img, height, url }) {
  const card2Style = {
    backgroundImage: `url(${img})`,
    width: "300px",
    height: `${height}`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "5px",
    textDecoration: "none"
  }
  const card2stylebutton = {
    fontSize: "18px",
    fontFamily: "Inter",
    border: "1px solid black",
    height: "25px",
    width: "150px",
    textAlign: "center",
    marginBottom: "20px",
    backgroundColor: "white",
    cursor: "pointer",
    textDecoration: "none",
    color: "black"
  }
  return (
    <Link to={url} className="shopbycollection-card" style={card2Style}>
      <div className="shopbycollection-card-button" style={card2stylebutton}>EXPLORE NOW</div>
    </Link>
  )
}

function Card3({ height, width, img, url }) {
  const card2Style = {
    backgroundImage: `url(${img})`,
    width: `${width}`,
    height: `${height}`,
    backgroundSize: "cover",
    backgroundPosition: "centre",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "5px",
    cursor: "pointer"
  }
  const handleClick = () => {
    window.open(url)
  }
  return (
    <div className="shopbycollection-card" style={card2Style} onClick={handleClick}>
      <div className="instagram-logo"></div>
    </div>

  )
}

export function Home() {

  const [array, setArray] = useState([{
    name: "New Arrival 1",
    path: require("../images/new-arrivals/1.png")
  }, {
    name: "New Arrival 1",
    path: require("../images/new-arrivals/2.png")
  }, {
    name: "New Arrival 3",
    path: require("../images/new-arrivals/3.png")
  }, {
    name: "New Arrival 4",
    path: require("../images/new-arrivals/4.png")
  }, {
    name: "New Arrival 5",
    path: require("../images/new-arrivals/5.png")
  }, {
    name: "New Arrival 6",
    path: require("../images/new-arrivals/6.png")
  }, {
    name: "New Arrival 6",
    path: require("../images/new-arrivals/6.png")
  }, {
    name: "New Arrival 6",
    path: require("../images/new-arrivals/6.png")
  }])

  return (
    <div className="home">
      <CarouselFadeExample />
      <div className="home-new-arrivals">
        <div className="home-new-arrivals-head">
          <div className="home-new-arrivals-head-1">Curated Exclusively For You</div>
          <div className="home-new-arrivals-head-2">NEW ARRIVALS</div>
        </div>
        <div className="home-new-arrivals-card">
          {
            array.map((el) => (
              <Card img={el.path} name={el.name} />
            ))
          }
        </div>
      </div>
      <div className="home-new-arrivals">
        <div className="home-new-arrivals-head">
          <div className="home-new-arrivals-head-1">Curated Exclusively For You</div>
          <div className="home-new-arrivals-head-2">SHOP BY COLLECTIONS</div>
        </div>
        <div className="home-shop-by-collections-card">
          <div className="shop-by-collections-main-1">
            <Card2 url="/category/lehengas"  height="320px" img={require("../images/shop-by-collections/1.png")} />
            <Card2 url="/category/indian wear" height="320px" img={require("../images/shop-by-collections/2.png")} />
          </div>
          <Card2 url="/category/sarees" height="650px" img={require("../images/shop-by-collections/3.png")} />
          <div className="shop-by-collections-main-1">
            <Card2 url="/category/suits" height="320px" img={require("../images/shop-by-collections/4.png")} />
            <Card2 url="/category/indo western" height="320px" img={require("../images/shop-by-collections/5.png")} />
          </div>
          <Card2 url="/category/casuals" height="650px" img={require("../images/shop-by-collections/6.jpg")} />
        </div>
      </div>
      <div className="home-new-arrivals">
        <div className="home-new-arrivals-head">
          <div className="home-new-arrivals-head-1">Curated Exclusively For You</div>
          <div className="home-new-arrivals-head-2">B.. ON SOCIAL MEDIA</div>
        </div>
        <div className="home-social-media-card">
          <div className="social-media-main-1">
            <Card3 height="320px" width="310px" img={require("../images/instagram/1.webp")} url={"https://www.instagram.com/p/CdIoXaXBvDf/?igshid=NGQ4NzlkYzMwNA%3D%3D"} />
            <Card3 height="320px" width="310px" img={require("../images/instagram/2.webp")} url={"https://www.instagram.com/p/CdLX51lhLQ4/?igshid=NGQ4NzlkYzMwNA%3D%3D"} />
            <Card3 height="320px" width="310px" img={require("../images/instagram/3.webp")} url={"https://www.instagram.com/p/CdnBmftsmCc/?igshid=NGQ4NzlkYzMwNA%3D%3D"} />
            <Card3 height="320px" width="310px" img={require("../images/instagram/4.webp")} url={"https://www.instagram.com/p/ClGHDY8gqia/?igshid=NGQ4NzlkYzMwNA%3D%3D"} />
          </div>
          <Card3 height="650px" width="570px" img={require("../images/instagram/5.jpg")} url={"https://www.instagram.com/p/Ct9aL6SM-ec/?igshid=NGQ4NzlkYzMwNA%3D%3D"} />
        </div>
      </div>
    </div>
  )
}