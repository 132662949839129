import "../style/profile.css"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


function Address() {

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [contact, setContact] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [pincode, setPincode] = useState(null)
    const [userId, setUserId] = useState("")
    const [access_token, setAccess_tokens] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem("uid")
        if (token) {
            setUserId(token)
        }
        if (localStorage.getItem("token")) {
            setAccess_tokens(localStorage.getItem("token"))
        }
    })

    const updateProfileData = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_DOMAIN}/api/user/update-user`,
                {
                    email,
                    name,
                    contact,
                    address,
                    city,
                    state,
                    pincode,
                    userId
                },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );
            toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT });
            navigate("/profile")
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        }
    };


    return (
        <Form className="profile-right" onSubmit={updateProfileData}>
            {/* <Row className="mb-3" style={{ width: "100%" }}> */}
            <Form.Group className="mb-3" controlId="formGridEmail" style={{ width: "80%", display:"flex",flexDirection:"column", justifyContent:"flex-start" }}>
                <Form.Label style={{ fontSize: "22px", fontWeight: "500", fontFamily: "Poppins", textAlign: "left" }}>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridPassword" style={{ width: "80%", display:"flex",flexDirection:"column", justifyContent:"flex-start" }}>
                <Form.Label style={{ fontSize: "22px", fontWeight: "500", fontFamily: "Poppins", textAlign: "left" }}>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            {/* </Row> */}

            <Form.Group className="mb-3" controlId="formGridEmail" style={{ width: "80%", display:"flex",flexDirection:"column", justifyContent:"flex-start" }}>
                <Form.Label style={{ fontSize: "22px", fontWeight: "500", fontFamily: "Poppins", textAlign: "left" }}>Contact</Form.Label>
                <Form.Control type="text" placeholder="Enter Contact" value={contact} onChange={(e) => setContact(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress2" style={{ width: "80%" , display:"flex",flexDirection:"column", justifyContent:"flex-start"}}>
                <Form.Label style={{ fontSize: "22px", fontWeight: "500", fontFamily: "Poppins", textAlign: "left" }}>Address</Form.Label>
                <Form.Control placeholder="Apartment, studio, or floor" value={address} onChange={(e) => setAddress(e.target.value)} />
            </Form.Group>

            {/* <Row className="mb-3" style={{ width: "100%" }}> */}
            <Form.Group className="mb-3" controlId="formGridCity" style={{ width: "80%", display:"flex",flexDirection:"column", justifyContent:"flex-start" }}>
                <Form.Label style={{ fontSize: "22px", fontWeight: "500", fontFamily: "Poppins", textAlign: "left" }}>City</Form.Label>
                <Form.Control type="text" placeholder="Enter City" value={city} onChange={(e) => setCity(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridCity" style={{ width: "80%", display:"flex",flexDirection:"column", justifyContent:"flex-start" }}>
                <Form.Label style={{ fontSize: "22px", fontWeight: "500", fontFamily: "Poppins", textAlign: "left" }}>State</Form.Label>
                <Form.Control type="text" placeholder="Enter State" value={state} onChange={(e) => setState(e.target.value)} />
            </Form.Group>


            <Form.Group className="mb-3" controlId="formGridZip" style={{ width: "80%", display:"flex",flexDirection:"column", justifyContent:"flex-start" }}>
                <Form.Label style={{ fontSize: "22px", fontWeight: "500", fontFamily: "Poppins", textAlign: "left" }}>Pincode</Form.Label>
                <Form.Control type="number" placeholder="Enter Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
            </Form.Group>
            {/* </Row> */}

            <Button style={{ backgroundColor: "black", color: "white", textAlign: "center" }} type="submit">
                Update
            </Button>
            <ToastContainer />
        </Form>
    );
}

export default Address;