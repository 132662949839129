import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCkuFgmm9xzjpmd8oIP1JzSk-w41PMsom0",
  authDomain: "garment-website.firebaseapp.com",
  projectId: "garment-website",
  storageBucket: "garment-website.appspot.com",
  messagingSenderId: "65405781782",
  appId: "1:65405781782:web:1cbdd25fa387c23c5a7237",
  measurementId: "G-82E3Y7VZZV"
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)


// jhhbhbhj