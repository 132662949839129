import "../style/signup.css"
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from "../utils/LoaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth"
import { auth } from "../config/firebase-config"
import axios from "axios"

export function SignUp() {

    const navigate = useNavigate()
    const [timeoutId, setTimeoutId] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const readLocalStorageData = () => {
            const dataFromLocalStorage = localStorage.getItem('token');
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
        readLocalStorageData();
    }, []);

    useEffect(() => {
        const user = localStorage.getItem("token")
        if (user) {
            navigate("/profile")
        }
    }, [])

    const [contact, setContact] = useState("")
    const [change, setChange] = useState(false)
    const [otp, setOtp] = useState("")

    const sendOTP = async () => {
        try {
            const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {})
            const confirmation = await signInWithPhoneNumber(auth, contact, recaptcha)
            window.confirmation = confirmation
            setChange(true)
            const id = setTimeout(() => {
                window.open("/", "_self")
            }, 30000)
        }
        catch (error) {
	    console.log(error)
            toast.error("Please provide valid credentials! If you are doing so, then something went wrong! ", { position: toast.POSITION_TOP_RIGHT })
        }
    }

    const verifyOTP = async () => {
        try {
            clearTimeout(timeoutId)
            const data = await window.confirmation.confirm(otp)
            console.log(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/auth/sign-up`,123)
            axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/auth/sign-up`, { contact: data.user.phoneNumber, userId: data.user.uid }).then((response) => {
                navigate("/profile")
                toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT })
            }).catch((error) => {
                console.log(error)
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                    } else if (error.response.status === 404) {
                        toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                    } else if (error.response.status === 401) {
                        toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                    } else {
                        toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                    }
                } else if (error.request) {
                    toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
                } else {
                    toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
                }
                signOut(auth).then(() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('uid');
                });
            })
        }
        catch (error) {
            toast.error("Invalid OTP !", { position: toast.POSITION_TOP_RIGHT });
        }
    }

    if (loading) {
        return <><LoaderComponent /></>
    }

    return (
        <div className="sign-up">
            <div className="sign-up-container">
                <div className="page-title">Sign Up Page</div>
                {change ? (
                    <div className="input-group">
                        <input
                            type="number"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <div className="submit-button" onClick={verifyOTP}>
                            Submit
                        </div>
                    </div>
                ) : (
                    <div className="phone-input-container">
                        <PhoneInput
                            className="input-group"
                            country={'in'}
                            value={contact}
                            onChange={(contact) => setContact("+" + contact)}
                        />
                        <div className="submit-button" onClick={sendOTP}>
                            Click
                        </div>
                        <div id="recaptcha"></div>
                    </div>
                )}
            </div>
            <ToastContainer />
        </div>
    )
}
