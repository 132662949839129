import "../style/profile.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { saveAs } from 'file-saver';
import axios from "axios"
import { getAuth, signOut } from "firebase/auth"


function AllUsers() {
    const [user, setUser] = useState([])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/all-users`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }).then((response) => {
            setUser(response.data.users)
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
    }, [])
    return (
        <div className="profile-right-all-users">
            {
                user.map((ele, indx) => (
                    <div className="all-users-admin-ins">
                        <div className="all-users-admin-data">Name - {ele.name}</div>
                        <div className="all-users-admin-data">Contact - {ele.contact}</div>
                        <div className="all-users-admin-data">Email - {ele.email}</div>
                    </div>
                ))
            }
            <ToastContainer />
        </div>
    )
}

function AllOrders() {
    const handleClick = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/download-all-orders`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
            })
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'orders.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success('File downloaded successfully!', { position: toast.POSITION_TOP_RIGHT });
        } catch (error) {
            console.log(error, 123)
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else if (error.response.status === 404, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        }

    }
    return (
        <div className="profile-right">
            <div className="profile-right-order-head">My Orders</div>
            <div className="profile-right-order-ins" style={{ backgroundColor: "black", border: "1px solid black", color: "white", padding: "10px", cursor: "pointer", fontSize: "20px", fontFamily: "Poppins" }} onClick={handleClick}>
                Click here to download all orders !
            </div>
        </div>
    )
}

function CreateProduct() {
    const [token, setToken] = useState(null)
    useEffect(() => {
        if (localStorage.getItem("token")) {
            setToken(localStorage.getItem("token"))
        }
    })
    const [formData, setFormData] = useState({
        name: '',
        category: 'sarees',
        colors: [],
        quantity: '',
        size: [],
        fabricDetails: '',
        price: '',
        images: [],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'size') {
            const selectedSizes = Array.from(e.target.selectedOptions, (option) => option.value);
            setFormData((prevData) => ({ ...prevData, size: selectedSizes }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleImageChange = (e) => {
        const files = e.target.files;
        setFormData((prevData) => ({ ...prevData, images: [...files] }));
    };

    const handleColorChange = (e) => {
        const enteredColors = e.target.value.split(',').map((color) => color.trim());
        setFormData((prevData) => ({ ...prevData, colors: enteredColors }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();

        for (const key in formData) {
            if (key === 'images') {
                formData[key].forEach((file) => {
                    formDataToSend.append('images', file);
                });
            } else if (formData[key] instanceof Array) {
                formData[key].forEach((value) => {
                    formDataToSend.append(key, value);
                });
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/create-product`, formDataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT })
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else if (error.response.status === 404, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        }

    };
    return (
        <div className="profile-right-all-products">

            <Form onSubmit={handleSubmit} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap", gap: "20px" }} encType="multipart/form-data">
                <Form.Group controlId="productName" style={{ width: "80%", textAlign: "left" }}>
                    <Form.Label>Product Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="category" style={{ width: "80%", textAlign: "left" }}>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                        as="select"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                    >
                        <option value="saares">saares</option>
                        <option value="suits">suits</option>
                        <option value="indian wear">indian wear</option>
                        <option value="indo western">indo western</option>
                        <option value="evening wear">evening wear</option>
                        <option value="lehengas">lehengas</option>
                        <option value="casuals">casuals</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="quantity" style={{ width: "80%", textAlign: "left" }}>
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                        type="number"
                        name="quantity"
                        value={formData.quantity}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="price" style={{ width: "80%", textAlign: "left" }}>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="images" style={{ textAlign: "left" }}>
                    <Form.Label>Images (multiple)</Form.Label>
                    <Form.Control
                        type="file"
                        name="images"
                        onChange={handleImageChange}
                        required
                        multiple
                    />
                </Form.Group>
                <Form.Group controlId="fabricDetails" style={{ width: "80%", textAlign: "left" }}>
                    <Form.Label>Fabric Details</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="fabricDetails"
                        value={formData.fabricDetails}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="sizes" style={{ width: "80%", textAlign: "left" }}>
                    <Form.Label>Sizes (multiple)</Form.Label>
                    <Form.Control
                        as="select"
                        multiple
                        name="size"
                        value={formData.size}
                        onChange={handleChange}
                        required
                    >
                        <option value="XS">XS</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="XLL">XLL</option>
                        required
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Hold down the Ctrl (windows) / Command (Mac) button to select multiple sizes.
                    </Form.Text>
                    {formData.size.length > 0 && (
                        <div className="tags-container">
                            {formData.size.map((size, index) => (
                                <span key={index} className="badge badge-info mr-2">
                                    {size}
                                </span>
                            ))}
                        </div>
                    )}
                </Form.Group>
                <Form.Group controlId="colors" style={{ width: "80%", textAlign: "left" }}>
                    <Form.Label>Colors (multiple)</Form.Label>
                    <Form.Control
                        type="text"
                        name="colors"
                        value={formData.colors.join(', ')} // Display colors as a comma-separated string
                        onChange={handleColorChange}
                        placeholder="Enter colors separated by commas"
                        required
                    />
                    <Form.Text className="text-muted">
                        Enter colors separated by commas. Example: Red, Blue, Green
                    </Form.Text>
                </Form.Group >
                <Button variant="primary" type="submit" style={{ width: "20%" }}>
                    Submit
                </Button>
            </Form>
            <ToastContainer />
        </div>
    )
}

function UpdateItem() {

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [productData, setProductData] = useState([])
    const [updatedDetails, setUpdatedDetails] = useState({
        name: '',
        colors: [],
        sizes: [],
        quantity: 0,
        fabricDetails: '',
        price: 0,
        category: 'sarees',
    });

    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;
        if (name === "sizes") {
            updatedValue = value
                .toUpperCase()
                .split(',')
                .map((size) => size.trim());
        } else if (name === "colors") {
            updatedValue = value.split(',').map((color) => color.trim());
        }
        setUpdatedDetails({
            ...updatedDetails,
            [name]: updatedValue,
        });
    };

    const handleFormSubmit = (e, _id) => {
        e.preventDefault()
        axios.patch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/update-product?_id=${_id}`, updatedDetails, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((response) => {
            toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT })
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else if (error.response.status === 404, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
        setPopupOpen(false);
    };

    const handleUpdate = () => {
        setPopupOpen(true);
    };

    const handleDelete = (_id) => {
        axios.delete(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/delete-product?_id=${_id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((response) => {
            toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT })
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else if (error.response.status === 404, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/get-all-products`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((response) => {
            setProductData(response.data.items)
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else if (error.response.status === 404, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401, { position: toast.POSITION_TOP_RIGHT }) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT });
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
    }, [])

    return (
        <div className="product-card-admin-ins">
            {
                productData.map((data) => (
                    <div className="product-details-admin-ins">
                        <h2 className='h2-admin-ins'>{data.name}</h2>
                        <div className="product-info-admin-ins">
                            <p className='h2-admin-ins'>Colors: {data.colors.join(', ')}</p>
                            <p className='h2-admin-ins'>Sizes: {data.size.join(', ')}</p>
                            <p className='h2-admin-ins'>Quantity: {data.quantity}</p>
                            <p className='h2-admin-ins'>Fabric Details: {data.fabricDetails}</p>
                            <p className='h2-admin-ins'>Price: Rs{data.price}</p>
                            <p className='h2-admin-ins'>Category: {data.category}</p>
                        </div>
                        <div className="product-buttons-admin-ins" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px", flexDirection: "row" }}>
                            <button className="button-admin-ins" onClick={handleUpdate} style={{ textDecoration: "none" }}>Update</button>
                            <button className="button-admin-ins" onClick={() => handleDelete(data._id)}>Delete</button>
                        </div>


                        {isPopupOpen && (
                            <div className="popup-admin-ins">
                                <div className="popup-content-admin-ins">
                                    <h2>Update Product Details</h2>
                                    <form onSubmit={(e) => { handleFormSubmit(e, data._id) }} className="form-admin-ins">
                                        <label className="label-admin-ins">
                                            Name:
                                            <input
                                                className="input-admin-ins"
                                                type="text"
                                                name="name"
                                                value={updatedDetails.name}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                        <label className="label-admin-ins">
                                            Colors (comma-separated):
                                            <input
                                                className="input-admin-ins"
                                                type="text"
                                                name="colors"
                                                value={updatedDetails.colors}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                        <label className="label-admin-ins">
                                            Sizes (comma-separated):
                                            <input
                                                className="input-admin-ins"
                                                type="text"
                                                name="sizes"
                                                value={updatedDetails.sizes}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                        <label className="label-admin-ins">
                                            Quantity:
                                            <input
                                                className="input-admin-ins"
                                                type="number"
                                                name="quantity"
                                                value={updatedDetails.quantity}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                        <label className="label-admin-ins">
                                            Fabric Details:
                                            <input
                                                className="input-admin-ins"
                                                type="text"
                                                name="fabricDetails"
                                                value={updatedDetails.fabricDetails}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                        <label className="label-admin-ins">
                                            Price:
                                            <input
                                                className="input-admin-ins"
                                                type="number"
                                                name="price"
                                                value={updatedDetails.price}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                        <label className="label-admin-ins">
                                            Category:
                                            <select
                                                className="input-admin-ins"
                                                name="category"
                                                value={updatedDetails.category}
                                                onChange={handleInputChange}
                                            >
                                                <option value="sarees">sarees</option>
                                                <option value="suits">suits</option>
                                                <option value="indian wear">indian wear</option>
                                                <option value="indo western">indo western</option>
                                                <option value="evening wear">evening wear</option>
                                                <option value="lehengas">lehengas</option>
                                                <option value="casuals">casuals</option>
                                            </select>
                                        </label>
                                        <button className="button-admin-ins" type="submit" >Save Changes</button>
                                        <button className="button-admin-ins" type="button" onClick={handleClosePopup}>
                                            Cancel
                                        </button>
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                ))
            }
        </div>
    )
}

export const Admin = () => {

    const navigate = useNavigate()
    const [navigator, setNavigator] = useState("main")
    const [isSelected, setIsSelected] = useState(-1);
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('uid') !== "tQKjweN0PYPilyDjXVQzX1wevLI3") {
            setShow(false)
            navigate("*")
        }
        else {
            setShow(true)
        }
    })

    const handleClick = (index) => {
        setIsSelected(index)
        if (index === 0) {
            setNavigator("all-users")
        }
        else if (index === 1) {
            setNavigator("all-orders")
        }
        else if (index === 2) {
            setNavigator("create-product")
        }
        else if (index === 3) {
            setNavigator("update-product")
        }
        else if (index === -1) {
            setNavigator("main")
        }
    };

    const logout = () => {
        const auth = getAuth()
        const dummy = signOut(auth).then(() => {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            navigate("/")
        })
        return dummy
    }

    return (
        <div className="profile">
            <div className="profile-left">
                <div className="profile-left-head">Hello Admin</div>
                <div className="profile-left-welcome">Welcome to your Account</div>
                <div className={`profile-left-info ${isSelected === -1 ? 'selected' : ''}`} onClick={() => handleClick(-1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M10.0002 11.6666C12.3013 11.6666 14.1668 9.80117 14.1668 7.49998C14.1668 5.19879 12.3013 3.33331 10.0002 3.33331C7.69898 3.33331 5.8335 5.19879 5.8335 7.49998C5.8335 9.80117 7.69898 11.6666 10.0002 11.6666ZM10.0002 11.6666C6.31826 11.6666 3.3335 13.9052 3.3335 16.6666M10.0002 11.6666C13.6821 11.6666 16.6668 13.9052 16.6668 16.6666" stroke="#807D7E" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                    <div className="profile-left-info-data">Profile</div>
                </div>
                <div className={`profile-left-info ${isSelected === 0 ? 'selected' : ''}`} onClick={() => handleClick(0)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M10.0002 11.6666C12.3013 11.6666 14.1668 9.80117 14.1668 7.49998C14.1668 5.19879 12.3013 3.33331 10.0002 3.33331C7.69898 3.33331 5.8335 5.19879 5.8335 7.49998C5.8335 9.80117 7.69898 11.6666 10.0002 11.6666ZM10.0002 11.6666C6.31826 11.6666 3.3335 13.9052 3.3335 16.6666M10.0002 11.6666C13.6821 11.6666 16.6668 13.9052 16.6668 16.6666" stroke="#807D7E" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                    <div className="profile-left-info-data">All Users Details</div>
                </div>
                <div className={`profile-left-info ${isSelected === 1 ? 'selected' : ''}`} onClick={() => handleClick(1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M15.5833 8.39473L15.5138 7.45768C15.4501 6.59928 14.7083 5.93421 13.8146 5.93421H11.9937M5.5 17.4167H4.45365C3.465 17.4167 2.68398 16.609 2.75442 15.6594L3.36283 7.45769C3.42651 6.59928 4.16831 5.93421 5.06207 5.93421H6.88298M6.88298 5.93421V4.29385C6.88298 2.93494 8.02705 1.83333 9.43833 1.83333C10.8496 1.83333 11.9937 2.93494 11.9937 4.29385V5.93421M6.88298 5.93421H11.9937M15.5833 13.75C15.5833 14.7625 14.7625 15.5833 13.75 15.5833C12.7375 15.5833 11.9167 14.7625 11.9167 13.75M10.0833 19.25H17.4167C18.4292 19.25 19.25 18.4292 19.25 17.4167V12.8333C19.25 11.8208 18.4292 11 17.4167 11H10.0833C9.07081 11 8.25 11.8208 8.25 12.8333V17.4167C8.25 18.4292 9.07081 19.25 10.0833 19.25Z" stroke="#807D7E" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                    <div className="profile-left-info-data">All Orders Placed</div>
                </div>
                <div className={`profile-left-info ${isSelected === 2 ? 'selected' : ''}`} onClick={() => handleClick(2)}>
                    <svg className="profile-left-info-img" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10.0002 11.6667C12.3013 11.6667 14.1668 9.80119 14.1668 7.5C14.1668 5.19882 12.3013 3.33334 10.0002 3.33334C7.69898 3.33334 5.8335 5.19882 5.8335 7.5C5.8335 9.80119 7.69898 11.6667 10.0002 11.6667ZM10.0002 11.6667C6.31826 11.6667 3.3335 13.9052 3.3335 16.6667M10.0002 11.6667C13.6821 11.6667 16.6668 13.9052 16.6668 16.6667" stroke="#807D7E" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                    <div className="profile-left-info-data">Create New Product</div>
                </div>
                <div className={`profile-left-info ${isSelected === 3 ? 'selected' : ''}`} onClick={() => handleClick(3)}>
                    <svg className="profile-left-info-img" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10.0002 11.6667C12.3013 11.6667 14.1668 9.80119 14.1668 7.5C14.1668 5.19882 12.3013 3.33334 10.0002 3.33334C7.69898 3.33334 5.8335 5.19882 5.8335 7.5C5.8335 9.80119 7.69898 11.6667 10.0002 11.6667ZM10.0002 11.6667C6.31826 11.6667 3.3335 13.9052 3.3335 16.6667M10.0002 11.6667C13.6821 11.6667 16.6668 13.9052 16.6668 16.6667" stroke="#807D7E" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                    <div className="profile-left-info-data">Update Product</div>
                </div>
                <div className={`profile-left-info ${isSelected === 4 ? 'selected' : ''}`} onClick={logout}>
                    <svg className="profile-left-info-img" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M14.6667 15.5833L19.25 11M19.25 11L14.6667 6.41667M19.25 11H8.25M8.25 2.75H7.15C5.60986 2.75 4.83978 2.75 4.25153 3.04973C3.73408 3.31338 3.31338 3.73408 3.04973 4.25153C2.75 4.83978 2.75 5.60986 2.75 7.15V14.85C2.75 16.3901 2.75 17.1602 3.04973 17.7485C3.31338 18.2659 3.73408 18.6866 4.25153 18.9503C4.83978 19.25 5.60986 19.25 7.15 19.25H8.25" stroke="#807D7E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div className="profile-left-info-data">Sign Out</div>
                </div>
            </div>
            {
                navigator === "main" ?
                    (
                        <div className="profile-right-admin">
                            WELCOME TO ADMIN DASHBOARD
                        </div>
                    ) :
                    navigator === "all-users" ?
                        (<AllUsers />) :
                        navigator === "all-orders" ?
                            (<AllOrders />) : navigator === "update-product" ? (<UpdateItem />) :
                                (<CreateProduct />)
            }
            <ToastContainer />
        </div>
    );
};


