import "./deliverytimeline.css"

export function DeliveryTimeline() {
    return (
        <div className="delivery-timeline-container">
            <h1 style={{ fontWeight: "800", marginTop: "10px" }}>Delivery Timelines</h1>

            <section >
                <h2>We Provide Worldwide Shipping</h2>
                <p>The shipping cost is calculated at the time of Check Out only, based on your Area Zip Code and weight.</p>
            </section>

            <section>
                <h2 >When do we deliver?</h2>
                <ul >
                    <li><strong>Indian / Bridal Wear:</strong> All domestic and International orders will take about 6-8 Weeks for dispatch from the date of order.</li>
                    <li><strong>Western Pret:</strong> All domestic and International orders will take about 2 - 4 Weeks for dispatch from the date of order.</li>
                    <li><strong>Shoes:</strong> The process and dispatch time for shoe orders are 4-6 weeks from the date of order.</li>
                    <li><strong>Bags/Belts:</strong> The standard Process and dispatch timeline for bags are 2-3 weeks from the date of order.</li>
                    <li><strong>Jewellery:</strong> The process time for jewellery is 10 working days from the date of order.</li>
                    <li><strong>RTS (Ready To Ship):</strong> All The RTS Products will be dispatched within 24-48Hours from the date of purchase.</li>
                </ul>
            </section>

            <section>
                <h2>Shipping: Where? When? How? How much?</h2>
                <p>We provide shipping to more than 200 countries around the world. The shipping cost is calculated at the time of Check Out based on your Area Zip Code and weight.</p>
                <p>For more information, you can reach our Customer Care Team at <strong style={{ color: "#9E4244" }}> customercare@BellisimobyTanvi.com or +918450038213.</strong></p>
                <p>If you can't wait for your order, you can request Express Shipping by writing to customercare@BellisimobyTanvi.com. The cost for the same will be communicated to you at that time.</p>
            </section>

            <section>
                <h2 style={{ color: "#9E4244" }}>Cash on Delivery (do we?)</h2>
                <p><strong>Sorry! We do not offer the Cash On Delivery Option!</strong></p>
            </section>

            <section>
                <h2>When do we deliver?</h2>
                <ul >
                    <li><strong>Indian / Bridal Wear:</strong> All domestic and International orders will take about 8 - 10 Weeks for dispatch from the date of order.</li>
                    <li><strong>Western Pret:</strong> All domestic and International orders will take about 2 - 4 Weeks for dispatch from the date of order.</li>
                    <li><strong>Shoes:</strong> The process and dispatch time for shoe orders are 4-6 weeks from the date of order.</li>
                    <li><strong>Bags/Belts:</strong> The process and dispatch time for accessory orders are 3 Weeks from the date of order.</li>
                    <li><strong>Jewellery:</strong> The process time for jewellery is 10 working days from the date of order.</li>
                </ul>
                <p>Note: We do not take responsibility for any delay in delivery due to causes beyond our control.</p>
            </section>

            <section>
                <h2>Taxes & Duties ('tis but a must!)</h2>
                <p><strong>For Indian Customers:</strong> Product Prices displayed are inclusive of all taxes and duties.</p>
                <p><strong>For International Customers:</strong> Most countries are shipped to on a DDU (Delivery Duty Unpaid) basis, which means product prices displayed are exclusive of all import duties. The recipient is liable for all import duties, customs, and local sales taxes levied by the country you are in, payment of these at the time of delivery is necessary to release your order from customs on arrival.</p>
                <p>For more information, please write to us on <strong style={{ color: "#9E4244" }}>customercare@BellisimobyTanvi.com</strong></p>
            </section>

            <section>
                <h2>Important Information</h2>
                <p>Order cut-off times are provided as guidelines only to give you a fair idea of when we may come knocking at your door! These do not take into account possible delays caused by payment authorization.</p>
                <p>We aim to dispatch all orders within 6-8 Weeks, however, estimated delivery times are to be used as a guide only and commence from the date of dispatch. We are not responsible for any delays caused by destination customs clearance processes, only responsible for making you look gorgeous and feeling fabulous!</p>
                <p>We are unable to redirect orders once items have been dispatched, and all orders require a signature upon receipt.</p>
            </section>

            <section>
                <p>For further information on the order status, you may contact our Customer Care Team at <strong style={{ color: "#9E4244" }}> customercare@BellisimobyTanvi.com or +918450038213.</strong></p>
            </section>

        </div>
    );


}