import React, { useState, useEffect } from 'react';
import size from "../images/size-guide.png"
import Accordion from 'react-bootstrap/Accordion';
import axios from "axios"
import chroma from 'chroma-js'
import styled from 'styled-components';
import '../style/product.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CartAction, WishlistAction } from '../redux/actions/counterAction';

const SizeSelector = ({ selectedSize, setSelectedSize }) => {

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  return (
    <SizeSelectorContainer>
      <SizeButtons>
        {sizes.map((size) => (
          <SizeButton
            key={size}
            onClick={() => handleSizeSelect(size)}
            selected={selectedSize === size}
          >
            {size}
          </SizeButton>
        ))}
      </SizeButtons>
    </SizeSelectorContainer>
  );
};

const sizes = ['S', 'M', 'L', 'XL', 'XXL'];

const SizeSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height:auto;
`;

const SizeButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const SizeButton = styled.button`
  background-color: ${(props) => (props.selected ? '#000' : '#fff')}; /* Black for selected, White for unselected */
  color: ${(props) => (props.selected ? '#fff' : '#000')}; /* White for selected, Black for unselected */
  border: 1.4px solid #000; /* Black border for all buttons */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width:40px;
  height:37px;
  display:flex;
  justify-content:center;
  align-items:center;

  &:hover {
    background-color: ${(props) => (props.selected ? '#333' : '#eee')}; /* Darker black for selected, Lighter grey for unselected */
    color: ${(props) => (props.selected ? '#fff' : '#000')};
  }
`;






function BasicExample({ data }) {
  return (
    <Accordion flush style={{ width: "100%" }}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className='custom-accordion-header' style={{ fontSize: "18px", fontFamily: "Poppins" }}>Description</Accordion.Header>
        <Accordion.Body style={{ textAlign: "left", color: "grey", fontFamily: "Poppins" }}>
          {data}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header className='custom-accordion-header' style={{ fontSize: "18px", fontFamily: "Poppins" }}>Delivery Timelines</Accordion.Header>
        <Accordion.Body style={{ textAlign: "left", color: "grey", fontFamily: "Poppins" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header className='custom-accordion-header' style={{ fontSize: "18px", fontFamily: "Poppins" }}>Additional Information</Accordion.Header>
        <Accordion.Body style={{ textAlign: "left", color: "grey", fontFamily: "Poppins" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export const Product = () => {
  const dispatch = useDispatch()
  const [selectedSize, setSelectedSize] = useState(null);
  const [userId, setUserId] = useState(null)
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedColor, setSelectedColor] = useState(null);
  const [product, setProduct] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const { itemId } = useParams()
  const [token, setToken] = useState(null)
  useEffect(() => {
    const uid = localStorage.getItem("uid")
    if (uid) {
      setUserId(uid)
    }
    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"))
    }
  }, [])

  useEffect(() => {

    function fetch() {
      axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/item/one-item?_id=${itemId}`)
        .then((response) => {
          setProduct(response.data.item);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
            } else if (error.response.status === 404) {
              toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
            } else if (error.response.status === 401) {
              toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
            } else {
              toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
            }
          } else if (error.request) {
            toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
          } else {
            toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
          }
        });
    }
    fetch()

  }, []);

  const customColorMap = {
    'turquoise blue': '#00CED1',
  };

  const getColorValue = (color) => {
    if (customColorMap[color]) {
      return customColorMap[color];
    }
    try {
      const rgbColor = chroma(color).rgb();
      return `rgb(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]})`;
    } catch (error) {
      console.error(`Error converting color: ${color}`, error);
      return '#000000'
    }
  };

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  }

  const incrementQuantity = () => {
    if (selectedQuantity >= product.quantity) {
      toast.error(`Only ${product.quantity} are available currently !`, { position: toast.POSITION_TOP_RIGHT })
    }
    setSelectedQuantity((prevQuantity) => Math.min(prevQuantity + 1, product.quantity));
  };

  const decrementQuantity = () => {
    setSelectedQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  const handleClick = async () => {
    if (selectedSize && selectedColor) {
      await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/cart/add-to-cart`, {
        userId, size: selectedSize, itemId: product._id, itemName: product.name, colorChoose: selectedColor, quantity: selectedQuantity, price: product.price, imagePath: product.images[0].filename
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT })
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
          } else if (error.response.status === 404) {
            toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
          } else {
            toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
          }
        } else if (error.request) {
          toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
        } else {
          toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
        }
      })
    }
    else {
      toast.error("Please choose color and size for your choice !", { position: toast.POSITION_TOP_RIGHT })
    }
    dispatch(CartAction())
  }

  const wishlistAdder = async () => {
    if (selectedSize && selectedColor) {
      await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/wishlist/add-to-wishlist`, {
        userId, size: selectedSize, itemId: product._id, itemName: product.name, colorChoose: selectedColor, quantity: selectedQuantity, price: product.price, imagePath: product.images[0].filename
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT })
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
          } else if (error.response.status === 404) {
            toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
          } else {
            toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
          }
        } else if (error.request) {
          toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
        } else {
          toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
        }
      })
    }
    else {
      toast.error("Please choose color and size for your choice !", { position: toast.POSITION_TOP_RIGHT })
    }
    dispatch(WishlistAction())
  }

  return (
    <div className="product-page">
      <div className="product-images">
        {product.images?.map((image, index) => (
          <img
            key={index}
            src={`${process.env.REACT_APP_BACKEND_DOMAIN}/public/Images/${image.filename}`}
            alt={`Product ${index + 1}`}
            className={selectedImage === index ? 'selected' : ''}
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>
      <div className="main-image-and-details">
        <div className="main-image-container">
          {product.images && (
            <img
              src={`${process.env.REACT_APP_BACKEND_DOMAIN}/public/Images/${product.images[selectedImage].path.slice(14)}`}
              alt={`Product ${selectedImage + 1}`}
              className="main-image"
            />
          )}
        </div>
        <div className="product-details">
          <div className='product-details-name'>{product.name}</div>
          <div className='product-details-price'>Rs {product.price}</div>
          <div className="color-options">
            {product.colors &&
              product.colors.map((color, index) => {
                const backgroundColor = getColorValue(color);

                const colorNameStyle = {
                  position: 'absolute',
                  bottom: '0',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  color: '#000',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  display: 'none',
                };

                return (
                  <span
                    key={index}
                    className={`color-option ${selectedColor === color ? 'selected' : ''}`}
                    style={{
                      backgroundColor,
                      position: 'relative',
                      display: 'inline-block',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleColorChange(color)}
                    onMouseOver={() => {
                      document.getElementById(`colorName${index}`).style.display = 'block';
                    }}
                    onMouseOut={() => {
                      document.getElementById(`colorName${index}`).style.display = 'none';
                    }}
                  >
                    <span
                      id={`colorName${index}`}
                      style={colorNameStyle}
                    >
                      {color}
                    </span>
                  </span>
                );
              })}
          </div>
          <div className="quantity-options">
            <div className="quantity-label">Quantity</div>
            <div className="quantity-controls">
              <div onClick={decrementQuantity} disabled={selectedQuantity === 1} className="quantity-button" style={{ cursor: "pointer" }}>
                -
              </div>
              <span className="quantity-display">{selectedQuantity}</span>
              <div onClick={incrementQuantity} disabled={selectedQuantity === product.quantity} className="quantity-button" style={{ cursor: "pointer" }}>
                +
              </div>
            </div>
          </div>
          <SizeSelector selectedSize={selectedSize} setSelectedSize={setSelectedSize} />
          {
            product.quantity > 0 ? (
              <>
                <button onClick={handleClick} className='add-to-cart-btn'>Add to Cart</button>
                <button onClick={wishlistAdder} className='wishlist-btn'>Wishlist</button>
              </>
            ) : (
              <button className='add-to-cart-btn'>OUT OF STOCK</button>
            )
          }
          <BasicExample data={product.fabricDetails} />
        </div>
      </div>
      <ToastContainer />
    </div >
  );
};

