import axios from "axios"

export const CartAction = () => {
    return async function (dispatch) {
        axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/cart/count-cart-items?userId=${localStorage.getItem('uid')}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((response) => {
            dispatch({
                type: 'UPDATE_CART_COUNT',
                payload: response.data.numOfCartItems
            })
            localStorage.setItem('cartCount', response.data.numOfCartItems)
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const WishlistAction = () => {
    return async function (dispatch) {
        axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/wishlist/count-wishlist-items?userId=${localStorage.getItem('uid')}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((response) => {
            dispatch({
                type: 'UPDATE_WISHLIST_COUNT',
                payload: response.data.numOfWishlistItems
            })
            localStorage.setItem('wishlistCount', response.data.numOfWishlistItems)
        }).catch((error) => {
            console.log(error)
        })
    }
}
