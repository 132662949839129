import "./termsservices.css"

export function TermsServices() {
    return (
        <div className="terms-of-service-container">
            <h1>Terms of Service</h1>

            <p>
                You (“you” or “End User” or “your” or “Buyer” or “Customer”) are required to read and accept all of the terms and conditions laid down in this Terms and Conditions (“Terms and Conditions” or “T&amp;C” or “Terms” or “Agreement”) and the linked Privacy Policy, before you may use www.BellisimobyTanvi.com (hereinafter referred to as “Site” or “Bellisimo by Tanvi” or “Bellisimo by Tanvi” or “Papa Don’t Preach.com” or “Bellisimo by Tanvi” or “we” or “our” or “us”). The Site allows you to browse, select and purchase Indian Fashion Garments, dresses, tunics, shorts, pants, skirts, jumpsuits, scarves, belts, bags, sarees, blouses, jackets, gift cards and coupons (“Goods” or “Products” or “Services”).
            </p>

            <p>
                This T&amp;C is effective upon acceptance and governs the relationship between you and Bellisimo by Tanvi (hereinafter “Company”) including the sale and supply of any Products on the site. If this T&amp;C conflicts with any other document, the T&amp;C will prevail for the purposes of usage of the Site. If you do not agree to be bound by this T&amp;C and the Privacy Policy, you may not use the Site in any way.
            </p>

            <p>
                The Company provides the goods and services to you subject to the T&amp;C. Please read these terms and conditions carefully. These T&amp;C, as modified or amended from time to time, are a binding contract between the company and you. If you visit, use, or shop at the site (or any future site operated by the company, you accept these T&amp;C.
            </p>

            <p>
                If this T&amp;C conflicts with any other document, the T&amp;C will prevail for the purposes of usage of the Site. As a condition of purchase, the Site requires your permission to send you administrative and promotional emails. We will send you information regarding your account activity and purchases, as well as updates about our products and promotional offers. You can opt-out of our promotional emails anytime by clicking the unsubscribe link at the bottom of any of our email correspondences. Please see our Privacy Policy for details. We shall have no responsibility in any manner whatsoever regarding any promotional emails or SMS/MMS sent to you. The offers made in those promotional emails or SMS/MMS shall be subject to change at the sole discretion of the Company, and the Company owes no responsibility to provide you any information regarding such change.
            </p>

            <p>
                By placing an order, you make an offer to us to purchase products you have selected based on standard Site restrictions, Merchant specific restrictions, and on the terms and conditions stated below.
            </p>

            <p>
                You are required to create an account to purchase any product from the Site. This is required so we can provide you with easy access to print your orders and view your past purchases.
            </p>

            <p>
                The Company reserves the right to make any changes to our Terms and Conditions and/or our Privacy Policy (which is incorporated herein by reference) as we deem necessary or desirable without prior notification to you. We suggest you read our T&amp;C and Privacy Policy from time to time to stay informed of any changes. If we make changes and you continue to use our Site, you are impliedly agreeing to the revised T&amp;C and Privacy Policy expressed herein.
            </p>

            <h2>1. Description of Services</h2>
            <p>
                In the Site, Bellisimo by Tanvi provides users with access to Indian Fashion garments, dresses, tunics, shorts, pants, skirts, jumpsuits, scarves, belts, bags, sarees, blouses, jackets, gift cards and coupons (the “Service”).
            </p>

            <h2>2. General</h2>
            <p>
                This Agreement sets forth the terms and conditions that apply to the use of the Site by the User. By using this Site, the User agrees to comply with all of the T&amp;C hereof. The right to use the Site is personal to the User and is not transferable to any other person or entity. The User shall be responsible for protecting the confidentiality of their password(s), if any. The User acknowledges that, although the Internet is often an insecure environment, sometimes there are interruptions in service or events that are beyond the control of the Company, and the Company shall not be responsible for any data lost while transmitting information on the Internet.
            </p>

            {/* Continue rendering other sections ... */}

            <h2>3. Modified Terms</h2>
            <p>
                The Company reserves the right at all times to discontinue or modify any of our T&amp;C and/or our Privacy Policy as we deem necessary or desirable without prior notification to you. Such changes may include, among other things, the adding of certain fees or charges. We suggest to our users that they read our T&amp;C and Privacy Policy from time to time to stay informed.
            </p>

            <h2>4. Third-Party Services</h2>
            <p>
                We may allow access to or advertise certain third-party product or service providers (“Merchants”) from which you may purchase certain goods or services. You understand that we do not operate or control the products or services offered by Merchants. Merchants are responsible for all aspects of order processing, fulfillment, billing, and customer service. We are not a party to the transactions entered into between you and Merchants. You agree that use of or purchase from such Merchants is at your sole risk and is without warranties of any kind by us, expressed, implied, or otherwise, including warranties of title, fitness for purpose, merchantability, or non-infringement.
            </p>

            <h2>5. Third-Party Merchant Policies</h2>
            <p>
                All rules, policies (including privacy policies), and operating procedures of Merchants will apply to you while on such sites. We are not responsible for information provided by you to Merchants. We and the Merchants are independent contractors and neither party has the authority to make any representations or commitments on behalf of the other.
            </p>

            <h2>6. Membership Eligibility</h2>
            <p>
                The Service is available to users of all ages, however, it will not be available to any users suspended or removed from the system by Bellisimo by Tanvi for any reason. Users may not have more than one account. Maintaining more than one account by a user shall amount to fraudulent act on part of the user and attract actions against such user in accordance with the terms of clause 10 below. Additionally, users are prohibited from selling, trading, or otherwise transferring your Bellisimo by Tanvi account to another party. If you do not qualify, you may not use the Service or the Site.
            </p>
            <p>
                The Company owns no responsibility in any manner over any dispute arising out of transactions by any third party using your account/e-mail provided by you to the Company or payments made by your credit card by any third party.
            </p>

            <h2>7. Your Account</h2>
            <p>
                In consideration of your use of the Site, you represent that you are not a person barred from receiving services under the laws as applicable in India. You also agree to provide true, accurate, current and complete information about yourself as prompted by the Site’s registration form. If you provide any information that is untrue, inaccurate, not current, or incomplete (or becomes untrue, inaccurate, not current, or incomplete), or Bellisimo by Tanvi has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, Bellisimo by Tanvi has the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).
            </p>
            <p>
                If you use the Site, you are responsible for maintaining the confidentiality of your account and password, including cases when it is being used by any of your family members, friends or relatives, whether a minor or an adult. You further agree to accept responsibility for all transactions made from your account and any dispute arising out of any misuse of your account, whether by any family member, friend, relative, any third party, or otherwise shall not be entertained by the Company. Because of this, we strongly recommend that you exit from your account at the end of each session. You agree to notify Bellisimo by Tanvi immediately of any unauthorized use of your account or any other breach of security. Bellisimo by Tanvi reserves the right to refuse service, terminate accounts, or remove or edit content in its sole discretion.
            </p>

            <h2>8. Cancellation by Bellisimo by Tanvi</h2>
            <p>
                Please note that there may be certain orders that we are unable to accept and must cancel. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason. Some situations that may result in your order being canceled shall include limitations on quantities available for purchase, inaccuracies or errors in product or pricing information, problems identified by our credit and fraud avoidance team or any defect regarding the quality of the product. We may also require additional verifications or information before accepting any order. We will contact you if all or any portion of your order is canceled or if additional information is required to accept your order. If your order is canceled after your credit card has been charged, the said amount will be reversed back in your Card Account. Any type of voucher used in these orders shall be pushed back into the user’s account in case of cancellation by Bellisimo by Tanvi).
            </p>

            {/* Continue rendering other sections ... */}

            <h2>9. Cancellations by the Customer</h2>
            <p>
                In case of requests for order cancellations, Bellisimo by Tanvi reserves the right to accept or reject requests for order cancellations for any reason. As part of usual business practice, if we receive a cancellation notice and the order has not been processed/approved by us, we shall cancel the order and refund the entire amount. A request for the cancellation of the order shall be valid and accepted only if they are made within 24 (twenty-four) hours of making the order on the Site. We will not be able to cancel orders that have already been processed or orders for which a request for cancellation is made after the expiry of 24 (twenty hours) from making the order. Bellisimo by Tanvi has the full right to decide whether an order has been processed or not. The "Ready to Ship" collection products are exempted from this, and no cancellation requests will be accepted for the same. The customer agrees not to dispute the decision made by Bellisimo by Tanvi and accept Bellisimo by Tanvi’s decision regarding the cancellation. In the event of the cancellation of the order by the customer, Bellisimo by Tanvi shall not refund the voucher, if any used against such order.
            </p>

            <h2>10. Fraudulent or Declined Transactions</h2>
            <p>
                Our fraud detection team constantly monitors the user’s account to avoid fraudulent accounts and transactions. Users with more than one account or availing referral cards fraudulently shall be liable for legal actions under law and Bellisimo by Tanvi reserves the right to recover the cost of goods, collection charges, and lawyers' fees from persons using the Site fraudulently. Bellisimo by Tanvi reserves the right to initiate legal proceedings against such persons for fraudulent use of the Site and any other unlawful acts or omissions in breach of these terms and conditions. In the event of the detection of any fraudulent or declined transaction, before the initiation of legal actions, Bellisimo by Tanvi reserves the right to immediately delete such an account and dishonor all past and pending orders without any liability. For the purpose of this clause, Bellisimo by Tanvi shall owe no liability for any refunds. The user can view all cards issued to them under the “gift card” section after making a selection of the minimum purchase value.
            </p>

            {/* Continue rendering other sections ... */}

            <h2>11. Customer Dispute Resolution in case of sale of Coupons</h2>
            <p>
                Bellisimo by Tanvi may provide deals pertaining to any services and the same shall be redeemed by you through coupons (“Coupons” or “Deal”) sold through the Site. The Coupons sold through the Site may be in the form of cards. In case of any query pertaining to the use of Coupon/Deals, the Customer should notify or submit a request within 15 (fifteen) days from the date of purchase of the Deal or Coupon. Bellisimo by Tanvi will investigate and take reasonable steps to resolve and assist the customer. If you fail to contact us within a period of 15 (fifteen) days, your order shall be deemed to be accurate, and you hereby waive any right to dispute the order with Bellisimo by Tanvi. The Company can decline to entertain requests after due consideration. Your privacy is important to us, and we will protect it. We will not share your personal information with anyone other than those listed in our Privacy Policy. Any issues or concerns should be highlighted to Bellisimo by Tanvi by the Customer. Issues or concerns highlighted by any person/party other than the Customer will not be considered a bona fide dispute.
            </p>

            <h2>12. Prior appointment before using a Coupon/Deal</h2>
            <p>
                The Customer needs to take a prior appointment from the concerned merchant before using a Coupon or Deal. Bellisimo by Tanvi shall not be responsible in any manner in the event of late materialization of Deal to the Customer arising out of Customer’s failure to obtain prior appointments pertaining to the delivery/performance of such goods or services as envisaged in the Coupon/Deal. The time period for such prior appointment shall as mentioned in the Coupon/Deal, and the Customer shall be required to adhere to such a time period.
            </p>

            {/* Continue rendering other sections ... */}

            <h2>13. Information by the User to Bellisimo by Tanvi in case of lost, stolen, misused or mutilated Coupon</h2>
            <p>
                The Customer must notify Bellisimo by Tanvi immediately if the Coupon is lost, stolen, mutilated, not received, or if the customer suspects that the Coupon is being used without his/her consent. In the case of loss or theft of the Coupon, the Customer must email us at shop@bellisimobytanvi.com, on the receipt of this information will act as per its standard operating procedures. If the lost Coupon is subsequently received by the Customer, it should be destroyed and must not be used. Only the replacement/new Coupon issued by Bellisimo by Tanvi can be used.
            </p>

            <h2>14. Credit Card Details</h2>
            <p>
                You agree, understand, and confirm that the credit card details provided by you for availing of services on Bellisimo by Tanvi will be correct and accurate and you shall not use the credit card that is not lawfully owned by you, i.e. in a credit card transaction, you must use your own credit card. You further agree and undertake to provide the correct and valid credit card details to Bellisimo by Tanvi. Bellisimo by Tanvi will not be liable for any credit card fraud. The liability for the use of a card fraudulently will be on you, and the onus to ‘prove otherwise’ shall be exclusively on you.
            </p>

            {/* Continue rendering other sections ... */}

            <h2>15. Submitted Content</h2>
            <p>
                Bellisimo by Tanvi does not claim ownership of any materials you make available through the Site. At Bellisimo by Tanvi’s sole discretion, such materials may be included in the Service in whole or in part or in a modified form. With respect to such materials you submit or make available for inclusion on the Site, you grant Bellisimo by Tanvi a perpetual, irrevocable, non-terminable, worldwide, royalty-free and non-exclusive license to use, copy, distribute, publicly display, modify, create derivative works, and sublicense such materials or any part of such materials. You hereby represent, warrant, and covenant that any materials you provide do not include anything (including, but not limited to, text, images, music, or video) to which you do not have the full right to grant the license specified.
            </p>

            {/* Continue rendering other sections ... */}

            <h2>16. Limitation of Liability</h2>
            <p>
                You expressly understand and agree that Bellisimo by Tanvi and its subsidiaries, affiliates, officers, employees, agents, partners, and licensors shall not be liable to you for any direct, indirect, incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss of profits, opportunity, goodwill, use, data, or other intangible losses (even if Bellisimo by Tanvi has been advised of the possibility of such damages), resulting from the use of the site, sale and supply of goods content, or any related/unrelated services and other services offered on the website from time to time.
            </p>

            <h2>17. Indemnity</h2>
            <p>
                You agree to indemnify and hold Bellisimo by Tanvi (and its officers, directors, agents, subsidiaries, affiliates, joint ventures, and employees) harmless from any claim or demand, including but not limited to reasonable attorneys’ fees, or arising out of or related to your breach of this T&amp;C, or your violation of any law or the rights of a third party.
            </p>

            {/* Continue rendering other sections ... */}

            <h2>18. Electronic Communication</h2>
            <p>
                When you use the Site or send emails to Bellisimo by Tanvi, you are communicating with Bellisimo by Tanvi electronically. You consent to receive communications from Bellisimo by Tanvi electronically. Bellisimo by Tanvi will communicate with you by email, SMS, or by posting notices on the Site. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
            </p>

            <h2>19. Site-Provided Email and Postings</h2>
            <p>
                The Site may provide users with the ability to send email messages to other users and non-users and to post messages on the Site. Bellisimo by Tanvi is under no obligation to review any messages, information or content (“Postings”) posted on the Site by users and assumes no responsibility or liability relating to any such Postings. Notwithstanding the above, Bellisimo by Tanvi may from time to time monitor the Postings on the Site and may decline to accept and/or remove any email or Postings. You understand and agree not to use any functionality provided by the Site to post content or initiate communications that contain:
            </p>
            <ul>
                <li>Any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, profane, hateful, racially, ethnically or otherwise objectionable material of any kind, including, but not limited to, any material which encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any applicable local, state, national or international law.</li>
                <li>Advertisements or solicitations of any kind.</li>
                <li>Impersonate others or provide any kind of false information.</li>
                {/* Additional list items as needed */}
            </ul>

            {/* Continue rendering other sections ... */}

            <h2>20. Access to Password Protected/Secure Areas</h2>
            <p>
                Access to and use of password protected and/or secure areas of the Site is restricted to authorized users only. Unauthorized individuals attempting to access these areas of the Site may be subject to prosecution.
            </p>
            <h2>21. Modifications and Notification of Changes</h2>
            <p>
                Bellisimo by Tanvi reserves the right to make changes to the Site, related policies and agreements, this T&amp;C and the Privacy Policy at any time. If Bellisimo by Tanvi makes a material modification to this T&amp;C, it will notify you by: (i) sending an email to the email address associated with your account. Bellisimo by Tanvi is not responsible for your failure to receive an email due to the actions of your ISP or any email filtering service; therefore, you should add the Site to the list of domains approved to send you email (commonly known as your “whitelist”); and (ii) displaying a prominent announcement above the text of this T&amp;C or the Privacy Policy, as appropriate, for thirty (30) days, with (i) and (ii) being deemed sufficient notification, of such changes. After notice of a modification to the T&amp;C or the Privacy Policy has been posted for 30 days, the notice will be removed and a brief description of the modification and the date that it went into effect will be placed in the Historical Modifications section at the end of the T&amp;C. If you have not used the Site for more than 30 days, you should check these two sections for any modifications to the T&amp;C or Privacy Policy prior to any further use of the Site. Should you wish to terminate your account due to a modification to the T&amp;C or the Privacy Policy, you may do so by sending an email with the subject line “Termination” to the following email address: shop@bellisimobytanvi.com. If you choose to continue using the Site, you agree that by doing so you will be deemed to accept the new T&amp;C or Privacy Policy, as relevant. You agree that it is your moral obligation to provide us the updated email address.
            </p>

            <h2>22. Trademarks</h2>
            <p>
                The trademarks, logos and service marks (“Marks”) displayed on the Site are the property of Bellisimo by Tanvi and other respective persons. Users are prohibited from using any Marks for any purpose including, but not limited to use as metatags on other pages or sites on the World Wide Web without the written permission of Bellisimo by Tanvi. All information and content including any software programs available on or through the Site (“Content”) is protected by copyright. Users are prohibited from modifying, copying, distributing, transmitting, displaying, publishing, selling, licensing, creating derivative works or using any Content available on or through the Site for commercial or public purposes.
            </p>

            <p>
                The Site contains copyrighted material, trademarks and other proprietary information, including, but not limited to, text, software, photos, video, graphics, music, sound, and the entire contents of the Company protected by copyright as a collective work under the applicable copyright laws. The Company owns a copyright in the selection, coordination, arrangement and enhancement of such content, as well as in the content original to it. Users may not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the content, in whole or in part. Users may download / print / save copyrighted material for the User’s personal use only. Except as otherwise expressly stated under copyright law, no copying, redistribution, retransmission, publication or commercial exploitation of downloaded material without the express permission of the Company and the copyright owner is permitted.
            </p>

            <p>
                If copying, redistribution or publication of copyrighted material is permitted, no changes in or deletion of author attribution, trademark legend or copyright notice shall be made. The User acknowledges that he/she/it does not acquire any ownership rights by downloading copyrighted material. Trademarks that are located within or on the Site or a website otherwise owned or operated in conjunction with the Company shall not be deemed to be in the public domain but rather the exclusive property of the Company unless such site is under license from the trademark owner thereof in which case such license is for the exclusive benefit and use of the Company unless otherwise stated.
            </p>

            <p>
                User shall not upload post or otherwise make available on the Site any material protected by copyright, trademark or other proprietary right without the express permission of the owner of the copyright, trademark or other proprietary right. The Company does not have any express burden or responsibility to provide the Users with indications, markings or anything else that may aid the User in determining whether the material in question is copyrighted or trademarked. User shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights or any other harm resulting from such a submission. By submitting material to any public area of the Site, User warrants that the owner of such material has expressly granted the Company the royalty-free, perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate and distribute such material (in whole or in part) worldwide and/or to incorporate it in other works in any form, media or technology now known or hereafter developed for the full term of any copyright that may exist in such material. User also permits any other end user to access, view, store or reproduce the material for that end user’s personal use. User hereby grants the Company, the right to edit, copy, publish and distribute any material made available on the Site by the User. The foregoing provisions of Section 25 apply equally to and are for the benefit of the Company, its subsidiaries, affiliates and each shall have the right to assert and enforce such provisions directly or on its own behalf.
            </p>

            <h2>23. Billing</h2>
            <p>
                The price of our merchandise is inclusive of the VAT. The VAT charged shall depend upon the destination where the order has to be shipped. Bellisimo by Tanvi reserves the right to collect taxes for shipping charges wherever applicable.
            </p>

            <h2>24. Promotional Cards and Vouchers</h2>
            <p>
                There are certain types of limited period cards which can be issued, or canceled by Bellisimo by Tanvi as part of any campaign or scheme. The terms for these campaigns will be decided by Bellisimo by Tanvi at the time of the campaign and these should be acceptable to you.
            </p>

            <h2>25. Offer and Schemes/Voucher Redemption</h2>
            <p>
                Bellisimo by Tanvi reserves the right to change/modify/add/delete any of the terms and conditions prevailing on the different promotional schemes it introduces in the market from time to time. Bellisimo by Tanvi at its discretion can withdraw a particular scheme from the website or market. In case of any query, please contact us at shop@bellisimobytanvi.com.
            </p>

            <h2>26. Bellisimo by Tanvi Offer, Schemes and Contest</h2>
            <p>
                Bellisimo by Tanvi shall introduce various offers, schemes, or contests (“Contest”) at different times. The terms and conditions for such Contest shall be separately available on the Site. In the event of any conflict between these T&amp;C and the terms and conditions pertaining to any Contest, such terms and conditions shall prevail.
            </p>

            <p>
                While participating in Bellisimo by Tanvi’s Contest you hereby agree to release and hold Bellisimo by Tanvi (and its officers, directors, agents, subsidiaries, affiliates, joint ventures, and employees) harmless from any claim or demand, including but not limited to reasonable attorneys’ fees, or arising out of or related to your participation in the said Contest.
            </p>

            <h2>27. Termination and Survival of Terms after Agreement Ends</h2>
            <p>
                The Company may terminate this Agreement at any time. Without limiting the foregoing, the Company shall have the right to immediately terminate any passwords or accounts of the User in the event of any conduct by the User which the Company, in its sole discretion, considers to be unacceptable, or in the event of any breach by the User of this Agreement. Notwithstanding any other provisions of this T&amp;C or any general legal principles to the contrary, any provision of this T&amp;C that imposes or contemplates continuing obligations on a party will survive the expiration or termination of this T&amp;C.
            </p>

            <h2>28. Miscellaneous</h2>
            <p>
                If any of these conditions are deemed invalid, void, or for any reason unenforceable, the parties agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the unenforceable condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition. From time to time, Bellisimo by Tanvi may offer special promotional offers which may or may not apply to your Bellisimo by Tanvi account. To avail of such promotional offers, you agree to be bound by any additional terms and conditions for these special offers which are laid down by Bellisimo by Tanvi. This T&amp;C and the relationship between you and Bellisimo by Tanvi will be governed by the laws as applicable in India. Any disputes will be handled in the competent courts of New Delhi, India. The failure of Bellisimo by Tanvi to act with respect to a breach by you or others does not waive its right to act with respect to subsequent or similar breaches. Bellisimo by Tanvi does not guarantee it will take action against all breaches of this T&amp;C. Except as otherwise expressly provided in this T&amp;C, there shall be no third-party beneficiaries to this T&amp;C. This T&amp;C constitutes the entire agreement between you and Papa Don’t Preach or Bellisimo by Tanvi and governs your use of the Site, superseding any prior agreements between you and Bellisimo by Tanvi with respect to the Site.
            </p>

        </div>
    );

}