// Navbar.jsx
import logo from "../images/logo.png";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"
import "../style/navbar.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { CartAction, WishlistAction } from "../redux/actions/counterAction";
import { useDispatch, useSelector } from 'react-redux'

const MobileMenu = ({ isOpen, onClose }) => {
    const handleCategoryClick = () => {
        onClose(); // Close the mobile menu when a category is clicked
    };

    return (
        <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
            <Link to="/category/sarees" onClick={handleCategoryClick}>SAREES</Link>
            <Link to="/category/suits" onClick={handleCategoryClick}>SUITS</Link>
            <Link to="/category/indian wear" onClick={handleCategoryClick}>INDIAN WEAR</Link>
            <Link to="/category/indo western" onClick={handleCategoryClick}>INDIAN WESTERN</Link>
            <Link to="/category/evening-wear" onClick={handleCategoryClick}>EVENING WEAR</Link>
            <Link to="/category/dresses" onClick={handleCategoryClick}>DRESSES</Link>
            <Link to="/category/casuals" onClick={handleCategoryClick}>CASUALS</Link>
        </div>
    );
};

export const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const cartCount = useSelector(state => state.cartCount)
    const wishlistCount = useSelector(state => state.wishlistCount)

    useEffect(() => {
        dispatch(CartAction());
        dispatch(WishlistAction());
    });

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    const wishlist = () => {
        const token = localStorage.getItem("token");
        if (token) {
            navigate("/wishlist");
        } else {
            toast.error("Please kindly login first !", { position: toast.POSITION_TOP_RIGHT });
        }
    };

    const addToCart = () => {
        const token = localStorage.getItem("token");
        if (token) {
            navigate("/add-to-cart");
        } else {
            toast.error("Please kindly login first !", { position: toast.POSITION_TOP_RIGHT });
        }
    };

    return (
        <nav className="navbar">
            <Link to="/" className="navbar-left">
                <img src={logo} className="logo" alt="Logo" />
            </Link>
            <div className="navbar-center">
                <div className="desktop-menu">
                    <Link to="/category/sarees">SAREES</Link>
                    <Link to="/category/suits">SUITS</Link>
                    <Link to="/category/indian wear">INDIAN WEAR</Link>
                    <Link to="/category/indo western">INDIAN WESTERN</Link>
                    <Link to="/category/evening wear">EVENING WEAR</Link>
                    <Link to="/category/lehengas">LEHENGAS</Link>
                    <Link to="/category/casuals">CASUALS</Link>
                </div>
            </div>
            <div className="navbar-right">
                <Link to="/signup" className="icon1"></Link>
                <div className="icon2" onClick={wishlist}>
                    {wishlistCount > 0 && localStorage.getItem('token') && <span className="badge">{wishlistCount}</span>}
                </div>
                <div className="icon3" onClick={addToCart}>
                    {cartCount > 0 && localStorage.getItem('token') && <span className="badge">{cartCount}</span>}
                </div>
            </div>
            <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                ☰
            </div>
            <MobileMenu isOpen={isMobileMenuOpen} onClose={closeMobileMenu} />
            <ToastContainer />
        </nav>
    );
};
