import React from 'react';
import { Link } from 'react-router-dom';

export const Empty = () => {
    return (
        <div style={styles.container}>
            <img style={{ width: "320px", height: "250px", objectFit: "contain" }} src="https://tse1.mm.bing.net/th?id=OIP.LYQqnrVXzhLkxCjQldEEzwHaEq&pid=Api&P=0&h=180" alt="" />
            <h1 style={styles.title}>Your Cart is Empty</h1>
            <p style={styles.message}>
                It seems like you haven't added any items to your cart yet. Explore our products and add something you like!
            </p>
            <Link to="/" style={styles.link}>
                Continue Shopping
            </Link>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        paddingTop: '4vh',
        minHeight: '60vh',
        backgroundColor: '#f0f0f0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "white"
    },
    title: {
        fontSize: '2em',
        marginBottom: '10px',
        fontFamily: 'Poppins',
    },
    message: {
        fontSize: '1em',
        fontFamily: 'Poppins',
        color: '#555',
        marginBottom: '20px',
        color: "#807D7E"
    },
    link: {
        textDecoration: 'none',
        color: 'black',
        border: "1px solid black",
        padding: '10px 20px',
        borderRadius: '5px',
        fontFamily: 'Poppins',
        fontSize: '1em',
    },
};

