import "../style/footer.css"
import { Link } from 'react-router-dom';

export function Footer() {
    const handleClick = () => {
        window.open("https://www.instagram.com/bellisimobytanvi/?igshid=OGQ5ZDc2ODk2ZA%3D%3D")
    }
    return (
        <div className="new-footer">
            <div className="new-footer-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" onClick={handleClick} style={{ cursor: "pointer" }}>
                    <circle cx="15" cy="15" r="15" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3343 6.125C8.00959 6.125 6.125 8.00959 6.125 10.3343V19.753C6.125 22.0778 8.00959 23.9624 10.3343 23.9624H19.753C22.0778 23.9624 23.9624 22.0778 23.9624 19.753V10.3343C23.9624 8.00959 22.0778 6.125 19.753 6.125H10.3343ZM5.125 10.3343C5.125 7.4573 7.4573 5.125 10.3343 5.125H19.753C22.6301 5.125 24.9624 7.4573 24.9624 10.3343V19.753C24.9624 22.6301 22.6301 24.9624 19.753 24.9624H10.3343C7.4573 24.9624 5.125 22.6301 5.125 19.753V10.3343ZM15.5639 11.7706C14.884 11.6698 14.1897 11.7859 13.5797 12.1025C12.9697 12.419 12.475 12.9199 12.166 13.5337C11.857 14.1476 11.7494 14.8433 11.8586 15.5219C11.9678 16.2004 12.2882 16.8273 12.7741 17.3132C13.2601 17.7992 13.8869 18.1196 14.5655 18.2288C15.244 18.3379 15.9397 18.2304 16.5536 17.9214C17.1675 17.6124 17.6683 17.1177 17.9849 16.5077C18.3014 15.8976 18.4175 15.2033 18.3167 14.5235C18.2139 13.83 17.8908 13.188 17.3951 12.6923C16.8993 12.1966 16.2573 11.8735 15.5639 11.7706ZM13.1191 11.2149C13.9159 10.8014 14.8227 10.6498 15.7106 10.7815C16.6163 10.9158 17.4547 11.3378 18.1022 11.9852C18.7496 12.6326 19.1716 13.4711 19.3059 14.3768C19.4376 15.2647 19.2859 16.1715 18.8725 16.9682C18.4591 17.765 17.805 18.4111 17.0032 18.8146C16.2014 19.2182 15.2928 19.3587 14.4066 19.2161C13.5204 19.0735 12.7017 18.655 12.067 18.0203C11.4323 17.3856 11.0139 16.567 10.8713 15.6807C10.7287 14.7945 10.8692 13.8859 11.2727 13.0842C11.6763 12.2824 12.3224 11.6283 13.1191 11.2149ZM20.224 9.36343C19.9478 9.36343 19.724 9.58728 19.724 9.86343C19.724 10.1396 19.9478 10.3634 20.224 10.3634H20.2348C20.5109 10.3634 20.7348 10.1396 20.7348 9.86343C20.7348 9.58728 20.5109 9.36343 20.2348 9.36343H20.224Z" fill="black" />
                </svg>


            </div>
            <div className="new-footer-2">
                <Link to="/terms-services" className="new-footer-2-ins">Terms Of Services</Link>
                <Link to="/privacy-policy" className="new-footer-2-ins">Privacy Policy</Link>
                <Link to="/delivery-timelines" className="new-footer-2-ins">Delivery Timelines</Link>
                <Link to="/return-exchange-policy" className="new-footer-2-ins">Return & Exchange Policy</Link>
            </div>
            <div className="new-footer-3">
                <div className="new-footer-3-ins-wrap">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p className='new-footer-3-ins-wrap-data'>249, Kediya House Sanjeevani Sagar, Jabalpur Madhya Pradesh 482003</p>
                </div>
                <div className="new-footer-3-ins-wrap">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22 6L12 13L2 6" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p className='new-footer-3-ins-wrap-data'>customercare@BellisimobyTanvi.com</p>
                </div>
                <div className="new-footer-3-ins-wrap">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.0499 5C16.0267 5.19057 16.9243 5.66826 17.628 6.37194C18.3317 7.07561 18.8094 7.97326 18.9999 8.95L15.0499 5ZM15.0499 1C17.0792 1.22544 18.9715 2.13417 20.4162 3.57701C21.8608 5.01984 22.7719 6.91101 22.9999 8.94L15.0499 1ZM21.9999 16.92V19.92C22.0011 20.1985 21.944 20.4742 21.8324 20.7293C21.7209 20.9845 21.5572 21.2136 21.352 21.4019C21.1468 21.5901 20.9045 21.7335 20.6407 21.8227C20.3769 21.9119 20.0973 21.9451 19.8199 21.92C16.7428 21.5856 13.7869 20.5341 11.1899 18.85C8.77376 17.3147 6.72527 15.2662 5.18993 12.85C3.49991 10.2412 2.44818 7.27099 2.11993 4.18C2.09494 3.90347 2.12781 3.62476 2.21643 3.36162C2.30506 3.09849 2.4475 2.85669 2.6347 2.65162C2.82189 2.44655 3.04974 2.28271 3.30372 2.17052C3.55771 2.05833 3.83227 2.00026 4.10993 2H7.10993C7.59524 1.99522 8.06572 2.16708 8.43369 2.48353C8.80166 2.79999 9.04201 3.23945 9.10993 3.72C9.23656 4.68007 9.47138 5.62273 9.80993 6.53C9.94448 6.88792 9.9736 7.27691 9.89384 7.65088C9.81408 8.02485 9.6288 8.36811 9.35993 8.64L8.08993 9.91C9.51349 12.4135 11.5864 14.4864 14.0899 15.91L15.3599 14.64C15.6318 14.3711 15.9751 14.1858 16.3491 14.1061C16.723 14.0263 17.112 14.0555 17.4699 14.19C18.3772 14.5286 19.3199 14.7634 20.2799 14.89C20.7657 14.9585 21.2093 15.2032 21.5265 15.5775C21.8436 15.9518 22.0121 16.4296 21.9999 16.92Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p className='new-footer-3-ins-wrap-data'>+91 8450038213 </p>
                </div>
            </div>
            <div className="new-footer-4">© 2024 All Rights Reserved</div>
        </div>
    )
}