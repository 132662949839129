export const CartReducer = (state = parseInt(localStorage.getItem('cartCount')) || 0, action) => {
    switch (action.type) {
        case 'UPDATE_CART_COUNT':
            return action.payload
        default:
            return state
    }
}

export const WishlistReducer = (state = parseInt(localStorage.getItem('wishlistCount')) || 0, action) => {
    switch (action.type) {
        case 'UPDATE_WISHLIST_COUNT':
            return action.payload
        default:
            return state
    }
}