import "./refundpolicy.css"

export function RefundPolicy() {
    return (
        <div className="refund-container">
            <h1>Returns and Exchange Policy</h1>
            <p>
                Our garments and accessories are made with utmost care and love to dress
                you up perfectly.
            </p>
            <p>
                All embellished garments come with care instructions and our ‘Elves &
                the Dressmaker’ kit with extra beads and embellishments in case your
                garment needs a little fix-me-up! We have made every effort to display as
                accurately as possible the colors and details of our products that appear
                on the site. However, as computer monitors vary, we cannot guarantee that
                your monitor's/smart phone’s/iPad's display of any color will be
                completely accurate.
            </p>
            <p>
                Since all our garments and accessories are Made-to-Measure/order, we do
                not offer refunds on any goods once sold.
            </p>
            <p>
                However, if there is a genuine quality defect on a custom order (not from
                the sale section), then at our sole discretion, sold goods may be
                exchanged for a store credit if they meet our terms and conditions.
            </p>

            <h2>Exchange/Return Policy Conditions:</h2>
            <ul>
                <li>Items should be returned unused and with all tags still attached.</li>
                <li>
                    Returns that are damaged or soiled may not be accepted and may be sent
                    back to the customer.
                </li>
                <li>
                    Where provided, belts and any packaging such as authenticity cards, dust
                    bags, and leather tags should be included with your return.
                </li>
                <li>
                    All shoes should be tried on a carpeted surface before wear. In case of
                    a size issue in shoes, we can exchange the same for another size of
                    your request only once.
                </li>
                <li>
                    Briefs, swimming costumes, and bikini bottoms should be tried on over
                    underwear, without removing the protective adhesive strip. Returns may
                    not be accepted if this strip has been removed or if items are soiled,
                    and may be sent back to the customer.
                </li>
                <li>
                    Goods are faulty if they are received damaged. Items that are damaged
                    as a result of normal wear and tear are not considered to be faulty.
                </li>
                <li>Where possible, we will offer to repair faulty items.</li>
                <li>All custom made orders are non-returnable/exchangeable.</li>
            </ul>

            <h2>Inform us of the Defect/Damage</h2>
            <p>
                Upon receipt of your return and post verification of the defect/damage, a
                store credit of the same value will be issued to you.
            </p>
            <p>
                All you need to do is inform us of the Defect/Damage within 7 working
                days of the receipt of the product by writing to us at{' '}
                <a href="mailto:customercare@BellisimobyTanvi.com">
                    customercare@BellisimobyTanvi.com
                </a>{' '}
                with the following details:
            </p>
            <ul>
                <strong style={{ color: "#9E4244" }}>
                    <li>Order number</li>
                    <li>Full name</li>
                    <li>Contact number</li>
                    <li>Attached images of the defects/damage on the product</li>

                </strong>
            </ul>
            <p>Unidentified returns may be returned to the sender.</p>

            <p>
                Once the email is sent to us and is duly acknowledged by our Customer
                Care Goddesses, you may dispatch the product back to our address mentioned
                on your invoice within 7 working days of the receipt of the product and
                share the courier service details and tracking number with us. We are not
                responsible for any damages caused to the product during transit from
                your end.
            </p>

            <p>
                Do note that this Store Credit issued to you can be used to purchase
                another product of the same or higher value only. The store credit will
                be valid for up to six months from the date of issue. To thank you for
                your patience and trust, the new product will be shipped back to you free
                of any shipping cost!
            </p>

            <p>
                Please also note: All Sale pieces are <strong style={{ color: "#9E4244" }}> NON-EXCHANGEABLE / NON-REFUNDABLE.
                    ONCE BOUGHT, WE CONSIDER IT SOLD FOR FOREVER! 😊 </strong>
            </p>
        </div>
    );
}