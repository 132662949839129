import { Link } from "react-router-dom";
import { useEffect } from "react";

export function NotLoggedIn() {
    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Kindly Login First !</h1>
            <p style={styles.message}>To access this page, you need to be logged in. Please log in using your credentials.</p>
            <img src="http://clipartmag.com/images/sad-face-images-cartoon-31.jpg" style={{ height: "280px", width: "280px", objectFit: "contain" }} />
            <Link to="/signup" style={{ textDecoration: "none", color: "white", backgroundColor: "black", marginTop: "2vh", width: "200px", height: "50px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center" }}>Sign In Sign Up</Link>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        paddingTop: '4vh',
        minHeight: "60vh",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        fontSize: '2em',
        marginBottom: '10px',
        fontFamily: "Poppins",
        fontWeight: "800"
    },
    message: {
        fontSize: '1em',
        fontFamily: "Poppins",
        marginBottom: "2vh",
        color: "#807D7E"
    }
}