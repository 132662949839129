import "../style/addtocart.css"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CartAction, WishlistAction } from "../redux/actions/counterAction";
import { NotLoggedIn } from "../utils/NotLoggedIn";
import { Empty } from "../utils/Empty";
import LoaderComponent from "../utils/LoaderComponent";

export function Wishlist() {

    const dispatch = useDispatch()
    const [cartItems, setCartItems] = useState([]);
    const [token, setToken] = useState(null)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const readLocalStorageData = () => {
            const dataFromLocalStorage = localStorage.getItem('token');
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
        readLocalStorageData();
    }, []);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            setToken(localStorage.getItem("token"))
            setShow(true)
        }
        else {
            setShow(false)
        }
    }, [])

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/wishlist/all-wishlist-items`, { userId: `${localStorage.getItem("uid")}` }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }).then((response) => {
            console.log(response.data.wishlistItems)
            setCartItems(response.data.wishlistItems)
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    // toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
    }, [])


    const removeFromWishlist = async (_id) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/wishlist/remove-wishlist-item`, { _id }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT })
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
        dispatch(WishlistAction())
    };

    const AddToCart = async (item) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/cart/add-to-cart`, item, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT })
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
        removeFromWishlist(item._id)
        dispatch(CartAction())
    }

    if (loading) {
        return <><LoaderComponent /></>
    }


    if (show) {
        return (
            <>
                {cartItems && cartItems.length > 0 ? (
                    <div className="cart-page" style={{ marginTop: "20px" }}>
                        <div className="cart-items">
                            {cartItems.map((item, index) => (
                                <div className="cart-item" key={index}>
                                    <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/public/Images/${item.imagePath}`} className="product-image" alt={`Product ${index + 1}`} />
                                    <div className="item-details">
                                        <div className="item-details-inside" style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "bold" }}>{item.itemName}</div>
                                        <div className="item-details-inside" style={{ color: "grey", fontSize: "12px" }}>Rs {item.price}</div>
                                        <div className="item-details-inside" style={{ color: "grey", fontSize: "12px" }}>Color: {item.colorChoose.toUpperCase()}</div>
                                        <div className="item-details-inside" style={{ color: "grey", fontSize: "12px" }}>Quantity: {item.quantity}</div>
                                        <div className="cart-tem-btns" style={{ display: "flex", flexDirection: "row", gap: "12px", justifyContent: "center", alignItems: "center" }}>
                                            <button className="remove-button" onClick={() => removeFromWishlist(item._id)}>
                                                Remove
                                            </button>
                                            <button className="add-to-cart-button" onClick={() => AddToCart(item)}>
                                                Add To Cart
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="cart-page" style={{ marginTop: "20px", color: "black" }}>
                        <Empty />
                    </div>
                )}
                <ToastContainer />
            </>
        );
    }
    else {
        return <NotLoggedIn />
    }

}
