import {applyMiddleware, combineReducers} from "redux"
import { legacy_createStore } from "redux";
import {CartReducer,WishlistReducer} from "./reducers/counterReducer"
import {thunk} from 'redux-thunk'


const rootReducer = combineReducers({
    cartCount : CartReducer,
    wishlistCount: WishlistReducer
})

export const store = legacy_createStore(rootReducer,applyMiddleware(thunk))
