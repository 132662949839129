import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;

  h1 {
    font-size: 4.6rem;
    font-weight: 800;
    letter-spacing: 2px;
    margin: 0;
    animation: ${fadeIn} 0.9s ease-in-out infinite alternate;
    background: rgb(63,94,251);
    background: linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    background-size: 200% 200%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
  }
`;





const LoaderComponent = () => {
    return (
        <LoaderContainer>
            <h1>Loading ...</h1>
        </LoaderContainer>
    );
};

export default LoaderComponent;
