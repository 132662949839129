import "../style/buyorder.css"
import 'react-toastify/dist/ReactToastify.css';
import { Empty } from "../utils/Empty"
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { useState, useEffect } from "react"

export function BuyOrders() {

    const [data, setData] = useState([])

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/order/get-all-orders`, { userId: `${localStorage.getItem("uid")}` }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }).then((response) => {
            // console.log(response.data.order)
            setData(response.data.order)
        }).catch((error) => {
            console.log(error)
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
    })

    return (
        <div className="my-order">
            <h2>My Orders</h2>
            {
                data.length > 0 ? (
                    data.map((order) => (
                        <div className="my-order-main">
                            <div className="my-order-main-first">
                                <div className="my-order-main-first-ins">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" fill="807D7E" /></svg>
                                    <p className="order-data">{order.createdAt}</p>
                                </div>
                                <div className="my-order-main-first-ins">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" fill="807D7E" /></svg>
                                    <p className="order-data"><b>{order.payment_status.toUpperCase()}</b></p>
                                </div>
                                <div className="my-order-main-first-ins">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" fill="807D7E" /></svg>
                                    <p className="order-data" style={{textAlign:"left"}}>Estimated to be delivered after 15 days from the date you ordered.</p>
                                </div>
                            </div>
                            <div className="my-order-main-second">
                                <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/public/Images/${order.path}`} className="my-order-main-second-img" />
                                <div className="my-order-main-second-data">
                                    <div className="order-data-name" style={{textAlign:"left"}}>{order.service}</div>
                                    <div className="order-data-color"><p className="xxx" style={{ color: "black", fontSize: "18px", fontWeight: "500" }}>Color:</p>{order.color.toUpperCase()}</div>
                                    <div className="order-data-quantity"><p className="xxx" style={{ color: "black", fontSize: "18px", fontWeight: "500" }}>Quantity:</p>{order.quantity}</div>
                                    <div className="order-data-price"><p className="xxx" style={{ color: "black", fontSize: "18px", fontWeight: "500" }}>Rs</p>{order.price}</div>
                                </div>
                            </div>
                        </div>

                    ))
                ) : (
                    <Empty />
                )
            }
            <ToastContainer />
        </div>
    );
}