import 'bootstrap/dist/css/bootstrap.min.css'
import "./app.css"
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { BrowserRouter, Routes, Route, HashRouter as Router } from "react-router-dom"
import { SignUp } from './component/SignUp';
import { Profile } from './component/Profile';
import { Navbar } from './component/Navbar';
import { Footer } from './component/Footer';
import { Home } from './component/Home.';
import { Category } from './component/Category';
import { Admin } from './component/Admin';
import { Product } from './component/Product';
import { AddToCart } from './component/AddToCart';
import { Wishlist } from './component/Wishlist';
import { WhatsApp } from './component/WhatsApp';
import { RefundPolicy } from './policies/RefundPolicy';
import { TermsServices } from './policies/TermsServices';
import { PrivacyPolicy } from './policies/PrivacyPolicy';
import { DeliveryTimeline } from './policies/DeliveryTimeline';
import { NotFound } from './utils/NotFound';
import { N } from './component/N';

function App() {

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        localStorage.setItem('token', authUser.accessToken)
        localStorage.setItem('uid', authUser.uid)
      }
    })
    return unsubscribe
  })

  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/category/:type" element={<Category />} />
          <Route path="/add-to-cart" element={<AddToCart />} />
          <Route path="/admin" element={<Admin />} />
          <Route path='/product/:itemId' element={<Product />} />
          <Route path="/add-to-cart" element={<AddToCart />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-exchange-policy" element={<RefundPolicy />} />
          <Route path='/terms-services' element={<TermsServices />} />
          <Route path="/delivery-timelines" element={<DeliveryTimeline />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <WhatsApp />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
