import "../style/addtocart.css"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CartAction } from "../redux/actions/counterAction";
import { NotLoggedIn } from "../utils/NotLoggedIn";
import { Empty } from "../utils/Empty"
import LoaderComponent from "../utils/LoaderComponent";

export function AddToCart() {
    const dispatch = useDispatch()
    const initialOptions = {
        clientId: "Ac75oSDhDbYiGWUvyrtPW94LVD6V4lZnhLmqefA41kPNxqoCxPl8z6Iizm9hiXXZ2u-i2cAykmH_DY4Q",
        currency: "INR",
        intent: "capture",
    }

    const [cartItems, setCartItems] = useState([]);
    const [token, setToken] = useState(null)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const readLocalStorageData = () => {
            const dataFromLocalStorage = localStorage.getItem('token');
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
        readLocalStorageData();
    }, []);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            setToken(localStorage.getItem("token"))
            setShow(true)
        }
        else {
            setShow(false)
        }
    }, [])

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/cart/all-cart-items`, { userId: `${localStorage.getItem("uid")}` }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }).then((response) => {
            setCartItems(response.data.cartItems)
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    // toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
    })

    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    };

    const removeFromCart = async (_id) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/cart/remove-cart-item`, { _id }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            toast.success(response.data.message, { position: toast.POSITION_TOP_RIGHT })
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
        dispatch(CartAction())
    };

    const checkout = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/payment/create-checkout-session`, {
                cartItems,
                userId: localStorage.getItem("uid")
            }
            ).then((res) => {
                if (res.data.url) {
                    window.location.href = res.data.url
                }
            })
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        }
        dispatch(CartAction())
    }

    if(loading){
        return <><LoaderComponent /></>
    }

    if (show) {
        return (
            <>
                {cartItems.length && cartItems.length > 0 ? (
                    <div className="cart-page" style={{ marginTop: "20px" }}>
                        <div className="cart-items">
                            {cartItems.map((item, index) => (
                                <div className="cart-item" key={index}>
                                    <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/public/Images/${item.imagePath}`} className="product-image" alt={`Product ${index + 1}`} />
                                    <div className="item-details">
                                        <div className="item-details-inside">{item.itemName}</div>
                                        <div className="item-details-inside" style={{ color: "grey", fontSize: "12px" }}>Rs {item.price}</div>
                                        <div className="item-details-inside" style={{ color: "grey", fontSize: "12px" }}>{item.colorChoose.toUpperCase()}</div>
                                        <div className="item-details-inside" style={{ color: "grey", fontSize: "12px" }}>{item.quantity}</div>
                                    </div>
                                    <button className="remove-button" onClick={() => removeFromCart(item._id)}>
                                        Remove
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="checkout">
                            <p>Total: Rs {calculateTotal()}</p>
                            <button className="checkout-button" style={{ marginBottom: "20px" }} onClick={checkout}>
                                Checkout
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="cart-page" style={{ marginTop: "20px", color: "black" }}>
                        <Empty />
                    </div>
                )}
                <ToastContainer />
            </>
        );
    }
    else {
        return <NotLoggedIn />
    }

}
