import React from 'react'
import "../style/category.css"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FilterContainer = styled.div`
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 18vw;
  margin: 20px;

  @media (max-width: 800px) {
    width: 90vw;
    margin: 30px auto;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const PriceRange = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #555;
  }

  input {
    width: 100%;
    height: 8px;
    -webkit-appearance: none;
    background: #f1f1f1;
    border-radius: 4px;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: black;
      cursor: pointer;
    }
  }
`;

const ApplyButton = styled.button`
  padding: 12px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;

  &:hover {
    background-color: white;
    color:black;
    border:1.4px solid black
  }
`;

const FilterSection = ({ setFilter, setFilterItems }) => {
    const { type } = useParams()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [price, setPrice] = useState(0)

    const handleChange = (event) => {
        setPrice(event.target.value)
    }

    const handleClick = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/item/filter-item?category=${type}&price=${price}`).then((response) => {
            setFilterItems(response.data.filteredItems)
            setFilter(true)
            console.log(response.data)
            console.log(price)
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 404) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else if (error.response.status === 401) {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                } else {
                    toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                }
            } else if (error.request) {
                toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
            } else {
                toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
            }
        })
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <FilterContainer>
            <Title>Filter Products</Title>
            <PriceRange>
                <label htmlFor="priceRange">Price Range:</label>
                <input type="range" id="priceRange" min="0" max="10000" value={price} onChange={(event) => handleChange(event)} />
            </PriceRange>
            <ApplyButton onClick={handleClick}>Apply Filters</ApplyButton>
        </FilterContainer>
    );
}


const Product = ({ product }) => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`/product/${product._id}`)
    }
    return (
        <div className="product-card" onClick={handleClick}>
            <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/public/Images/${product.images[0].filename}`} alt={product.name} style={{ height: "400px", width: "300px", objectFit: 'cover' }} />
            {/* <img src={require(`../../../server/public/Images/${product.images[0].filename}`)} alt={product.name} style={{ height: "350px", width: "300px", objectFit: 'cover' }} /> */}
            <div className="product-card-inside-data">
                <div style={{ fontFamily: "Poppins", fontSize: "14px", color: "", textAlign: "left" }}>{product.name}</div>
                <div style={{ fontFamily: "Poppins", fontSize: "10px", color: "grey", textAlign: "left" }}>Rs{product.price}</div>
            </div>
        </div>
    );
};

const Products = ({ products }) => {

    return (
        <div className="products-container">
            {products.map((product) => (
                <Product key={product.id} product={product} />
            ))}
        </div>
    );
};

export function Category() {

    const { type } = useParams()
    const [products, setProducts] = useState([])
    const [info, setInfo] = useState("")
    const [filter, setFilter] = useState(false)
    const [filteredItems, setFilterItems] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setFilter(false)
                setFilterItems([])
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/item/all-items?category=${type}`);
                setProducts(response.data.items);
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                    } else if (error.response.status === 404) {
                        setProducts([])
                        setFilter(false)
                        setFilterItems([])
                        setInfo(error.response.data.message)
                    } else if (error.response.status === 401) {
                        toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                    } else {
                        toast.error(error.response.data.message, { position: toast.POSITION_TOP_RIGHT })
                    }
                } else if (error.request) {
                    toast.error("No response received. Please check your network connection.", { position: toast.POSITION_TOP_RIGHT });
                } else {
                    toast.error("An error occurred. Please try again later.", { position: toast.POSITION_TOP_RIGHT });
                }
            }
        };

        fetchData();
        return () => {
        }
    }, [type])

    return (
        <div className="App">
            <header className="App-header">
                <h1>{type.toUpperCase()}</h1>
            </header>
            <main>
                {
                    filter ? (
                        <>
                            <FilterSection setFilter={setFilter} setFilterItems={setFilterItems} />
                            <Products products={filteredItems} />
                        </>
                    ) : products.length > 0 ?
                        (
                            <>
                                <FilterSection setFilter={setFilter} setFilterItems={setFilterItems} />
                                <Products products={products} />
                            </>
                        ) :
                        (
                            <div style={{ height: "61vh", display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Poppins", fontSize: "3rem", margin: "auto" }}>
                                {info}
                            </div>
                        )
                }
            </main>
            <ToastContainer />
        </div>
    );
}


